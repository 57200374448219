// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-decome-jsx": () => import("./../../../src/pages/decome.jsx" /* webpackChunkName: "component---src-pages-decome-jsx" */),
  "component---src-pages-konmari-clothes-jsx": () => import("./../../../src/pages/konmari-clothes.jsx" /* webpackChunkName: "component---src-pages-konmari-clothes-jsx" */),
  "component---src-pages-konmari-overview-jsx": () => import("./../../../src/pages/konmari-overview.jsx" /* webpackChunkName: "component---src-pages-konmari-overview-jsx" */),
  "component---src-pages-konmari-toc-jsx": () => import("./../../../src/pages/konmari-toc.jsx" /* webpackChunkName: "component---src-pages-konmari-toc-jsx" */),
  "component---src-pages-test-tiny-gallery-jsx": () => import("./../../../src/pages/test_TinyGallery.jsx" /* webpackChunkName: "component---src-pages-test-tiny-gallery-jsx" */),
  "component---src-pages-test-tiny-photo-jsx": () => import("./../../../src/pages/test_TinyPhoto.jsx" /* webpackChunkName: "component---src-pages-test-tiny-photo-jsx" */),
  "component---src-pages-test-tiny-review-jsx": () => import("./../../../src/pages/test_TinyReview.jsx" /* webpackChunkName: "component---src-pages-test-tiny-review-jsx" */),
  "component---src-templates-listing-jsx": () => import("./../../../src/templates/listing.jsx" /* webpackChunkName: "component---src-templates-listing-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */)
}


import React, { Component } from "react";

const clearStyle = {
  clear: 'both'
};

function TinyClear () {
  return (
    <div style={clearStyle}></div>
  );
}

export default TinyClear;
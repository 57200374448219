import React, { Component } from "react";
import "./TinyPhoto.scss";

function TinyPhoto ({src, alt, left, right, center, point, small, medium, large, width, specialClass, caption, linked, col, url}) {
    const print = (caption ? caption : "");
    const altText = (alt ? alt : "");
    let outerClass = "outer";
    let divClass = "image";
    
    if (caption !== "") {
      divClass += " captioned-image";
    }

    if (col) {
      outerClass += " col";
    }

    if (left) {
      outerClass += " float-left";
    } else if (right) {
      outerClass += " float-right";
    } else if (center) {
      outerClass += " center";
    }

    if (small) {
      divClass += " img-sm";
    } else if (medium) {
      divClass += " img-md";
    } else if (large) {
      divClass += " img-lg";
    } else {
      divClass += " img-reg";
    }

    if (point) {
      return (
        <div className={outerClass}>
          <figure className="point">
            <img src={src} alt={altText} style={{"width": width + "px"}} className={specialClass} />
          </figure>
        </div>
      );
    }
    else if (linked) {
      return (
        <div className={outerClass}>
        <figure className={divClass}>
          <a href={src}><img src={src} alt={altText} style={{"width": width + "px"}} className={specialClass} /></a>
          <figcaption>{print}</figcaption>
        </figure>
        </div>
      );
    } else if (url) {
      return (
        <div className={outerClass}>
        <figure className={divClass}>
          <a href={url}><img src={src} alt={altText} style={{"width": width + "px"}} className={specialClass} /></a>
          <figcaption>{print}</figcaption>
        </figure>
        </div>
      );
    } else {
      return (
        <div className={outerClass}>
        <figure className={divClass}>
          <img src={src} alt={altText} style={{"width": width + "px"}} className={specialClass} />
          <figcaption>{print}</figcaption>
        </figure>
        </div>
      );
    }
}

export default TinyPhoto;
import React, { Component } from "react";
import "./Toggle.scss";

function Toggle ({children, title}) {
    const dropdownText = title ? title : 'read more...';
    const toggleId = Math.random() * 50 + 1;

    return (
      <div className="toggle">
        <input id={"collapsible" + toggleId} type="checkbox" className="toggle-indicator" />
        <label for={"collapsible" + toggleId} class="toggle-label">
          {dropdownText}
        </label>
        <div className="collapsible-content">
          <div className="collapsible-inner">
            {children}
          </div>
        </div>
      </div>
    );
}

export default Toggle;
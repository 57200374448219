import React, { Component } from "react";
import "./TinyReview.scss";

function TinyReview ({children, title, author, cover, link, linkText,
    rating, genre, tw, summary, opener}) {
  const coverUrl = "/img/" + cover;
  linkText = linkText ? "@" + linkText : "@source";

  var coverDisplay, linkDisplay, ratingDisplay, genreDisplay, twDisplay,
    summaryDisplay, openerDisplay;

  if (cover) {
    coverDisplay = (
      <div className="cover">
        <img src={coverUrl} alt={title} />
      </div>
    )
  }

  if (link) {
    linkDisplay = (
      <div className="link">
        <a href={link}>{linkText}</a>
      </div>)
  }

  if (rating) {
    var ratingFormatted = "";
    var ratingAlt = "";

    if (!Number(rating)) {
      ratingFormatted = "N/A";
    } else {
      const ratingNum = parseInt(rating);
      ratingAlt = ratingNum + " stars";

      let i = 1;
      while (i <= rating) {
        ratingFormatted += "\u2B50";
        i++;
      }

      i = ratingFormatted.length;
      while (i < 5) {
        ratingFormatted += "\u2605";
        i++;
      }
    }

    ratingDisplay = (
      <div className="rating" title={ratingAlt}>
        Rating: {ratingFormatted}
      </div>)
  }
  
  if (genre) {
    genreDisplay = (
      <div className="genre"s>
        Genre: {genre}
      </div>)
  }
  
  if (tw) {
    twDisplay = (
      <div className="triggerWarnings">
        Trigger Warnings: {tw}
      </div>)
  }

  if (summary) {
    summaryDisplay = (
      <div className="summary">
        Plot Summary: {summary}
      </div>)
  }

  if (opener) {
    openerDisplay = (
      <div className="opener">
        Opening Line: {opener}
      </div>)
  }

  /*
    New TinyReview
  */
  if (rating) {
    return (
        <div className="review">
          <div className="mainData">
            {coverDisplay}
            <div className="titleBlock">
              <h2>{title}</h2>
              <h3>{author}</h3>
            </div>
            <div className="properties">
              {linkDisplay}
              {ratingDisplay}
              {genreDisplay}
              {twDisplay}
            </div>
          </div>
          {summaryDisplay}
          {openerDisplay}
          <div className="reviewText">
            {children}
          </div>
        </div>
    );
  }
  /*
    Old TinyReview 
  */
  else {
    let ifLongTitle = "";

    if (title.length > 12) {
      ifLongTitle = "longTitle";
    }

    if (link) {
    return (
      <div className="review">
        <div className="metadata">
          <img src={coverUrl} alt={title} />
          <h2 className={ifLongTitle}>{title}</h2>
          <h3>{author}</h3>
        </div>
        <div className="highlight">
          <div class="summary">
            {children}
          </div>
          <div class="link">
            <a href={link}>{linkText}</a>
          </div>
        </div>
      </div>
    );
    } else {
      return (
        <div className="review">
          <div className="metadata">
            <img src={coverUrl} alt={title} />
            <h2 className={ifLongTitle}>{title}</h2>
            <h3>{author}</h3>
          </div>
          <div className="highlight">
            <div class="summary">
              {children}
            </div>
          </div>
        </div>
      );
    }
  }

}

export default TinyReview;
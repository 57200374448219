import React, { Component } from "react";
import "./TinyReview2.scss";

function TinyReview2 ({children, title, author, cover, link, linkText,
    rating, genre, tw, summary, opener}) {
  const coverUrl = "/img/" + cover;
  linkText = linkText ? "@" + linkText : "@Goodreads";

  var coverDisplay, linkDisplay, ratingDisplay, genreDisplay, twDisplay,
    summaryDisplay, openerDisplay;

  if (cover) {
    coverDisplay = (
      <div className="cover">
        <img src={coverUrl} alt={title} />
      </div>
    )
  }

  if (link) {
    linkDisplay = (
      <div className="link">
        <a href={link}>{linkText}</a>
      </div>)
  }

  if (rating) {
    var ratingFormatted = "";
    var ratingAlt = "";

    if (!Number(rating)) {
      ratingFormatted = "N/A";
    } else {
      const ratingNum = parseInt(rating);
      ratingAlt = ratingNum + " stars";

      let i = 1;
      while (i <= rating) {
        ratingFormatted += "\u2B50";
        i++;
      }

      i = ratingFormatted.length;
      while (i < 5) {
        ratingFormatted += "\u2605";
        i++;
      }
    }

    ratingDisplay = (
      <div className="rating" title={ratingAlt}>
        Rating: {ratingFormatted}
      </div>)
  }
  
  if (genre) {
    genreDisplay = (
      <div className="genre"s>
        Genre: {genre}
      </div>)
  }
  
  if (tw) {
    twDisplay = (
      <div className="triggerWarnings">
        Trigger Warnings: {tw}
      </div>)
  }

  if (summary) {
    summaryDisplay = (
      <div className="summary">
        <b>Plot Summary:</b> {summary}
      </div>)
  }

  if (opener) {
    openerDisplay = (
      <div className="opener">
        <b>Opening Line:</b> <q>{opener}</q>
      </div>)
  }

  return (
      <div className="tinyReview">
        <div className="mainData">
          {coverDisplay}
          <div className="bookOverview">
            <div className="titleBlock">
              <h2>{title}</h2>
              <h3>{author}</h3>
            </div>
            <div className="properties">
              {linkDisplay}
              {ratingDisplay}
              {genreDisplay}
              {twDisplay}
            </div>
          </div>
        </div>
        {summaryDisplay}
        {openerDisplay}
        <div className="reviewText">
          {children}
        </div>
      </div>
  );

}

export default TinyReview2;